import React from "react";
import { RouteComponentProps, StaticContext } from "react-router";

import { Prompter, PromptResponse } from "~/lib/prompts";
import {
	appStoreLink,
	desktopDownloadLink,
	isAndroidApp,
	isDesktopApp,
	isiOSApp,
	isMacos,
	isNative,
	isWindows,
	playStoreLink,
	withQueryParams,
} from "~/lib/utils";
import { desktopVersion as getDesktopVersion, mobileVersion, Version, versionGt } from "~/lib/version";

const requiredMobileVersion: Version = [1, 3, 0];
const recommendedMobileVersion: Version = [1, 3, 0];
const requiredMacOSDesktopVersion: Version = [1, 4, 27];
const recommendedMacOSDesktopVersion: Version = [1, 4, 27];
const requiredWindowsDesktopVersion: Version = [1, 4, 27];
const recommendedWindowsDesktopVersion: Version = [1, 4, 27];

const isAppUpToDatePromise = (async function (): Promise<{
	platform: "desktop" | "mobile" | null;
	updateRequired: boolean;
	updateRecommended: boolean;
}> {
	if (isNative) {
		return mobileVersion()
			.catch(() => {
				// if we can't access the app version it's likely because it's outdated.
				return [1, 1, 0] as Version;
			})
			.then(mobileVersion => {
				const updateRequired = versionGt(requiredMobileVersion, mobileVersion);
				const updateRecommended = versionGt(recommendedMobileVersion, mobileVersion);

				return { updateRequired, updateRecommended, platform: "mobile" };
			});
	}
	const desktopVersion = getDesktopVersion();
	if (desktopVersion) {
		let updateRequired;
		let updateRecommended;
		if (isMacos) {
			updateRequired = versionGt(requiredMacOSDesktopVersion, desktopVersion);
			updateRecommended = versionGt(recommendedMacOSDesktopVersion, desktopVersion);
		} else if (isWindows) {
			updateRequired = versionGt(requiredWindowsDesktopVersion, desktopVersion);
			updateRecommended = versionGt(recommendedWindowsDesktopVersion, desktopVersion);
		} else {
			throw new Error("Platform not implemented");
		}
		return { updateRequired, updateRecommended, platform: "desktop" };
	}
	return { updateRequired: false, updateRecommended: false, platform: null };
})();

export function userWillBePromptedToUpdateApp() {
	return isAppUpToDatePromise.then(({ updateRequired, updateRecommended }) => updateRequired || updateRecommended);
}

function sendToStore() {
	/* eslint-disable camelcase */
	if (isAndroidApp) window.open(withQueryParams(playStoreLink, { utm_content: "lib_update_prompt" }));
	else if (isiOSApp) window.open(withQueryParams(appStoreLink, { utm_content: "lib_update_prompt" }));
	else if (isDesktopApp) window.open(withQueryParams(desktopDownloadLink, { utm_content: "lib_update_prompt" }));
	/* eslint-enable camelcase */
}

function updatePromptContent(updateRequired: boolean, platform: "desktop" | "mobile") {
	if (platform === "desktop") {
		if (updateRequired) {
			return "Download and install the updated desktop app to continue using Fellow and access the latest features.";
		} else {
			return "Download and install the updated desktop app to access the latest features.";
		}
	}
	if (platform === "mobile") {
		if (updateRequired) {
			return "Install the updated mobile app to continue using Fellow and access the latest features.";
		} else {
			return "Install the updated mobile app to access the latest features.";
		}
	}
	throw new Error("Unimplemented");
}

const UpdatePrompt: React.FunctionComponent<{
	updateRequired?: boolean;
	platform: "desktop" | "mobile";
}> = ({ updateRequired = false, platform }) => {
	const [open, setOpen] = React.useState(true);

	const promptResolved = React.useCallback(
		(result: PromptResponse) => {
			if (result === true) {
				sendToStore();
			}
			// If the update isn't required at this point we can stop bugging the user
			// If it is we keep the prompt open forever
			if (updateRequired === false) {
				setOpen(false);
			}
		},
		[updateRequired],
	);

	if (open) {
		return (
			<Prompter
				type="alert"
				title="A new version of Fellow is available"
				content={updatePromptContent(updateRequired, platform)}
				yesText={platform === "desktop" ? "Download" : "Install"}
				yesOnly={updateRequired}
				noText="Remind me later"
				required
				noCloseButton
				resolve={promptResolved}
			/>
		);
	}
	return <></>;
};

function RequiredUpdatePromptFactory(platform: "desktop" | "mobile"): React.FunctionComponent {
	return function RequiredUpdatePrompt() {
		return <UpdatePrompt updateRequired platform={platform} />;
	};
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function ensureAppUpdated<T extends React.ComponentType<any>>(
	suspendedElement: Promise<{ default: T }>,
): Promise<{ default: T | React.FunctionComponent<RouteComponentProps<{}, StaticContext, {} | null | undefined>> }> {
	const [resolvedElement, { updateRequired, updateRecommended, platform }] = await Promise.all([
		suspendedElement,
		isAppUpToDatePromise,
	]);

	if (updateRequired && platform !== null) {
		return { default: RequiredUpdatePromptFactory(platform) };
	} else if (updateRecommended && platform !== null) {
		const typeRestrictedPlatform = platform;
		function WrappedOptionalUpdate(props: object) {
			const InnerComponent = resolvedElement.default;

			return (
				<>
					{/* @ts-ignore */}
					<InnerComponent {...props} />
					<UpdatePrompt platform={typeRestrictedPlatform} />
				</>
			);
		}

		return { default: WrappedOptionalUpdate };
	} else {
		return resolvedElement;
	}
}
