import { CSSProperties, SyntheticEvent, useEffect, useState } from "react";

import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import {
	faBat as Bat,
	faCat as Cat,
	faCow as Cow,
	faCrow as Crow,
	faDeer as Deer,
	faDog as Dog,
	faDove as Dove,
	faDuck as Duck,
	faElephant as Elephant,
	faFish as Fish,
	faFrog as Frog,
	faHorse as Horse,
	faKiwiBird as Kiwi,
	faMonkey as Monkey,
	faPig as Pig,
	faRabbit as Rabbit,
	faRam as Ram,
	faSheep as Sheep,
	faSnake as Snake,
	faSpider as Spider,
	faSquirrel as Squirrel,
	faTurtle as Turtle,
	faUnicorn as Unicorn,
	faWhale as Whale,
} from "@fortawesome/pro-solid-svg-icons";
import * as Sentry from "@sentry/browser";
import bowser from "bowser";
import copyToClipboardWithExecCommand from "copy-to-clipboard";
import { isThisYear, isToday, isTomorrow, isYesterday } from "date-fns";
import Immutable from "immutable";
import { capitalize, chunk, flatMap, flatten, isEqualWith, isFunction } from "lodash";
import { flow, map, replace, split } from "lodash/fp";
import moment from "moment";
import queryString from "query-string";
import uuid4 from "uuid/v4";

import { StreamData, UserData } from "~/components/nav/panels/Home";
import { hasOwnProperty } from "~/lib/js";

import { inCalendar, inVideocall } from "./browserExtension";
import store from "./store";

/**
 * Clean a hex color code, to return a 6-digit hex code without the '#'
 * @param hexStr The hex string to clean
 */
const cleanHex = (hexStr: string): string => {
	let output = hexStr.replace(/#/, "").trim();
	if (output.length === 3) {
		output = output.replace(/(.)/g, "$1$1");
	}
	return output;
};

/**
 * A function for interpolating colors. Adapted from https://gist.github.com/maxwells/8251275
 * @param left The color you start with. A percentage of 0 will result in this color.
 * @param right The color you end with. A percentage of 100 will result in this color.
 * @param percentage The amount to move between the colors.
 * @returns A new, opaque color as a hex code with out the '#' (to be consistent with UI_HEX_COLORS).
 */
const findColorBetween = (left: string, right: string, percentage: number) => {
	const leftHex = cleanHex(left);
	const rightHex = cleanHex(right);

	const interpolate = (leftValue: string, rightValue: string) => {
		const leftDecimal = parseInt(leftValue, 16);
		const rightDecimal = parseInt(rightValue, 16);

		const mixedValue = Math.round(leftDecimal + (rightDecimal - leftDecimal) * (percentage / 100));
		return mixedValue.toString(16);
	};

	const r = interpolate(leftHex.slice(0, 2), rightHex.slice(0, 2));
	const g = interpolate(leftHex.slice(2, 4), rightHex.slice(2, 4));
	const b = interpolate(leftHex.slice(4, 6), rightHex.slice(4, 6));

	return `${r}${g}${b}`;
};

export const getOpaqueFadedColor = (
	color: string,
	layer: "second" | "base" = "second",
	layerPercentageDark = 60,
	layerPercentageLight = 80,
) => {
	const layerVariable = layer === "base" ? "--baseLayerColor" : "--secondLayerColor";
	const bkgColor = rgbToHex(getComputedStyle(document.documentElement).getPropertyValue(layerVariable));

	if (document.documentElement.dataset.theme === "theme-dark") {
		return findColorBetween(color, bkgColor, layerPercentageDark);
	} else {
		return findColorBetween(color, bkgColor, layerPercentageLight);
	}
};

// Colors from our design library allocated to avatars.

// 200 - Flag Stem + Inactive Flag
// 600 - Active Ring + Active Flag
// 700 - Letter Backing + Tooltip backing
const UI_HEX_COLORS = [
	// [200, 600, 700]
	["FFCCCC", "D44243", "BA2728"],
	["FFE4BF", "F5834A", "DB662C"],
	["FFE9B3", "FCBF12", "E3A905"],
	["E0F7B5", "9ADD1C", "82C406"],
	["CEF5D9", "52CC75", "36B25A"],
	["C1F2E6", "00D5B6", "00BDA1"],
	["CCE9FF", "37A4FA", "1B8AE0"],
	["D7E1FC", "596FFF", "3950E5"],
	["D9D7FA", "8466FF", "6545E6"],
	["FFD1DD", "EA5278", "D1345B"],
];

const ANONYMOUS_ICONS: { [key: string]: IconDefinition } = {
	Bat,
	Cat,
	Cow,
	Crow,
	Deer,
	Dog,
	Dove,
	Duck,
	Elephant,
	Fish,
	Frog,
	Horse,
	Kiwi,
	Monkey,
	Pig,
	Rabbit,
	Ram,
	Sheep,
	Snake,
	Spider,
	Squirrel,
	Turtle,
	Unicorn,
	Whale,
};

const colorId = (s: string): number => {
	return s
		.split("")
		.map(x => x.charCodeAt(0))
		.reduce((x, y) => x + y, 10);
};

export const str2ColorHex = (s: string): string => UI_HEX_COLORS[colorId(s) % UI_HEX_COLORS.length][1];

/**
 * A function for converting css rgb functions to hex codes.
 * Needed now since we switched to using rgb values for the base colors in variables.css
 * @param rgb A color in css's rgb format. i.e. "rgb(255, 255, 255)"
 * @returns The same colour as a hex code, without the '#' (to be consistent with UI_HEX_COLORS).
 */

const rgbToHex = (rgb: string) => {
	// Strip away the spaces
	const noSpaces = rgb.replace(" ", "");

	// Remove css function
	const valuesString = noSpaces.split("(")[1].split(")")[0];

	// Separate by colour value and convert to base16
	const base16Values = valuesString.split(",").map(v => parseInt(v).toString(16));

	// Glue it back together
	return base16Values.join("");
};

export const str2AttendeeColors = (s: string) => {
	const position = colorId(s) % UI_HEX_COLORS.length;

	const colors = {
		flag: UI_HEX_COLORS[position][0],
		ring: UI_HEX_COLORS[position][1],
		tooltip: UI_HEX_COLORS[position][2],
		get selection() {
			return getOpaqueFadedColor(UI_HEX_COLORS[position][1]);
		},
	};

	return colors;
};

/**
 *	@deprecated
 */
export const uniqueId = (length = 8): string => {
	let id = "";
	while (id.length < length) {
		id += Math.random().toString(36).substr(2);
	}
	return id.substr(0, length);
};

export const nonFunctionEquals = <T extends { asMutable?: Immutable.Set<T> }>(A: T, B: T): boolean => {
	const cmp = (x: T, y: T): boolean => {
		if ((isFunction(x) && isFunction(y)) || x === y || (x == null && y == null)) {
			return true;
		} else if (x == null || y == null || (x.asMutable != null && y.asMutable != null)) {
			return false;
		}
		return false;
	};
	return isEqualWith(A, B, cmp);
};

const parser = bowser.getParser(window.navigator.userAgent);

export const toType = <T>(obj: T): string =>
	(({}).toString.call(obj).match(/\s([A-Za-z]+)/) || ["", ""])[1].toLowerCase();

export const getCaretPosition = (element: HTMLInputElement): { start: number; end: number | null } => {
	let start;
	if (document.selection) {
		// IE<9
		element.focus();
		const range = document.selection.createRange();
		const rangelen = range.text.length;
		range.moveStart("character", -element.value.length);
		start = range.text.length - rangelen;
		return { start, end: start + rangelen };
	} else if (element.selectionStart != null) {
		return { start: element.selectionStart, end: element.selectionEnd };
	} else {
		return { start: 0, end: 0 };
	}
};

export const setCaretPosition = (element: HTMLInputElement, caretPos: number, caretPosEnd: number): void => {
	if (element == null) {
		return;
	}
	const getPos = (pos: number): number => {
		if (pos === -1) {
			return element.value.length;
		} else {
			return pos;
		}
	};
	if (element.selectionStart != null) {
		element.focus();
		setTimeout(() => element.setSelectionRange(getPos(caretPos), getPos(caretPosEnd) || getPos(caretPos)), 0);
	} else {
		element.focus();
	}
};

/**
 *	Returns if an element is visible on page.
 *	Checks to see if it has offsetWidth / Height (this is what jQuery does internally)
 *
 *	@param element the element to test
 */
export const isVisible = (element: HTMLElement): boolean => {
	return false || (element && (element.offsetWidth > 0 || element.offsetHeight > 0));
};

const sumLength = (items: string[]): number => items.reduce((cur, x) => (cur > 0 ? cur + 1 : cur) + x.length, 0);

/**
 *	Returns the shortened name using initials.
 *
 *	@param name name to shorten
 *	@param opts =
 *		maxLength: maximum length to try to keep the name under.
 *		direction: whether to shorten forwards (1) or backwards (-1)
 *		separator: leave this at the default of " "
 */
export const getInitials = (
	name: string,
	opts: { maxLength?: number; direction?: number; separator?: string; curLength?: number; keepFirst?: boolean } = {},
): string => {
	let asc, step;
	let i, n;
	const { maxLength = null, direction = 1, separator = " ", curLength = 0, keepFirst = false } = opts;

	const names = name.split(separator);
	const getInitial = (n: string): string => {
		if (separator === "-") {
			return n[0] ? n[0].toUpperCase() : "";
		} else {
			return getInitials(n, { ...opts, separator: "-", curLength: sumLength(names) });
		}
	};

	const iterable = names.slice(1, -1);
	for (
		step = direction, asc = step > 0, i = asc ? 0 : iterable.length - 1;
		asc ? i < iterable.length : i >= 0;
		i += step
	) {
		n = iterable[i];
		if (maxLength && maxLength < (curLength || sumLength(names))) {
			names[i + 1] = getInitial(n);
		}
	}

	if (maxLength && maxLength < (curLength || sumLength(names))) {
		if (direction === 1) {
			names[names.length - 1] = getInitial(names[names.length - 1]);
		} else {
			names[0] = getInitial(names[0]);
		}
	}

	if (!keepFirst && maxLength && maxLength < (curLength || sumLength(names))) {
		if (direction === 1) {
			names[0] = getInitial(names[0]);
		} else {
			names[names.length - 1] = getInitial(names[names.length - 1]);
		}
	}

	let output = "";
	for (i = 0; i < names.length; i++) {
		n = names[i];
		if (i === names.length - 1 || (n.length <= 1 && names[i + 1].length <= 1)) {
			output += n;
		} else {
			output += n + separator;
		}
	}

	return output;
};

export const listFormat = <T>(
	list: readonly T[],
	opts: { sep: string | ((x: T, y: number) => (string | T) | (string | T)[]); last: string } = {
		sep: ", ",
		last: " and ",
	},
): (string | T)[] => {
	const { sep, last } = opts;
	const len = list.length;

	let sepFunc: (x: T, y: number) => (string | T) | (string | T)[];

	if (typeof sep === "string") {
		sepFunc = ((sep: string, last: string) => (x: T | string, i: number) => {
			if (i >= len - 1) {
				return x;
			}
			if (last) {
				return [x, i === len - 2 ? (len > 2 ? sep.trim() : "") + last : sep];
			} else {
				return [x, sep];
			}
		})(sep, last);
	} else {
		sepFunc = sep;
	}

	return flatten(list.map((x: T, i: number) => sepFunc(x, i)));
};

export interface TooltipProps {
	"data-tooltip": string;
	"data-position": string;
	"data-inverted"?: boolean;
	"data-tooltip-delayed"?: boolean;
	"data-center-arrow"?: boolean;
	"data-tooltip-width"?: boolean;
	style?: CSSProperties;
}

export interface SetTooltipOptions {
	position: string;
	inverted: boolean;
	delayed: boolean;
	width: string;
}

interface SetTooltip {
	(text: string, options?: Partial<SetTooltipOptions>): TooltipProps;
	(text: string, position?: string, inverted?: boolean, delayed?: boolean, width?: string): TooltipProps;
}

type OptsOrPosition = string | Partial<SetTooltipOptions>;

const setTooltipDefaults = Object.freeze({
	position: "bottom center",
	inverted: true,
	delayed: false,
	width: "",
});

export function clearTooltip(node: HTMLElement): void {
	node.removeAttribute("data-tooltip");
	node.removeAttribute("data-position");
	node.removeAttribute("data-inverted");
	node.removeAttribute("data-tooltip-delayed");
	node.removeAttribute("data-center-arrow");
	node.removeAttribute("data-tooltip-width");
	node.style.removeProperty("--tooltip-width");
}

/**
 * @deprecated Use ~/ui/Tooltip instead
 */
export const setTooltip: SetTooltip = (
	text: string,
	optsOrPosition?: OptsOrPosition,
	_inverted?: boolean,
	_delayed?: boolean,
	_width?: string,
): TooltipProps => {
	let inverted, delayed, width, position;

	if (typeof optsOrPosition === "object") {
		// can't use Object.assign since { a?: number } allows { a: undefined }
		// and Object.assign({ a: 123 }, { a: undefined }) is { a: undefined }
		position = optsOrPosition.position ?? setTooltipDefaults.position;
		inverted = optsOrPosition.inverted ?? setTooltipDefaults.inverted;
		delayed = optsOrPosition.delayed ?? setTooltipDefaults.delayed;
		width = optsOrPosition.width ?? setTooltipDefaults.width;
	} else {
		position = optsOrPosition ?? setTooltipDefaults.position;
		inverted = _inverted ?? setTooltipDefaults.inverted;
		delayed = _delayed ?? setTooltipDefaults.delayed;
		width = _width ?? setTooltipDefaults.width;
	}

	const props: TooltipProps = {
		"data-tooltip": text,
		"data-position": position,
	};

	if (inverted) {
		props["data-inverted"] = true;
	}
	if (delayed) {
		props["data-tooltip-delayed"] = true;
	}

	// If tooltip is not centered, let's adjust the arrow position a bit
	if (!position.includes("center")) {
		props["data-center-arrow"] = true;
	}

	if (width) {
		const style = { "--tooltip-width": width } as CSSProperties;
		props["data-tooltip-width"] = true;
		props.style = style;
	}

	return props;
};

export function formatDatetime(datetime: moment.MomentInput): string {
	return moment(datetime).format("LLL");
}

export const setDatetimeTooltip = (
	datetime: moment.MomentInput,
	position = "bottom center",
	inverted = true,
	delayed = true,
): TooltipProps => {
	const text = formatDatetime(datetime);
	return setTooltip(text, position, inverted, delayed);
};

export const setDateTooltip = (
	datetime: moment.MomentInput,
	position = "bottom center",
	inverted = true,
	delayed = true,
): TooltipProps => {
	const text = moment(datetime).format("MMMM DD, YYYY");
	return setTooltip(text, position, inverted, delayed);
};

export const insertBetween = <T>(array: T[], element: T): boolean[] =>
	flatMap(array, (value: T, index: number, original: typeof array) => {
		if (original.length - 1 !== index) {
			return [value, element];
		} else {
			return value;
		}
	});

export const splitInsert = (text: string, split: string, insert: string): boolean[] =>
	insertBetween(text.split(split), insert);

export const encodeCurrentPath = (): string => {
	if (!window.location) return "";
	return encodeURI(window.location.pathname) + encodeURIComponent(window.location.search);
};

export const nameFromNameEntry = (entry: string): string => {
	return flow(
		split(" "),
		map(x => capitalize(x)),
	)(entry).join(" ");
};

export const emailFromNameEntry = (entry: string, domain: string): string => {
	const email = entry.toLowerCase().split(" ");
	return `${email.join(".")}@${domain}`;
};

export const nameFromEmailEntry = (entry: string): string => {
	const head = entry.split("@")[0];
	return flow(
		replace(/\W+/g, " "),
		split(" "),
		map(x => capitalize(x)),
	)(head).join(" ");
};

/**
 * Simulates a click on an event. Sometimes necessary for our dropdowns which don't close by themselves :(
 * @param elem HTMLElement to click on
 */
export const simulateClick = function (elem: HTMLElement) {
	// Create our event (with options)
	let evt = new MouseEvent("click", {
		bubbles: true,
		cancelable: true,
		view: window,
	});
	// If cancelled, don't dispatch our event
	return !elem.dispatchEvent(evt);
};

/**
 * An event handler that will preventDefault and do nothing else.
 */
export const preventDefault = (e: Event | SyntheticEvent) => {
	e.preventDefault();
	return false;
};

/**
 * An event handler that will stopPropagation and preventDefault
 */
export function stopEvent(e: Event | SyntheticEvent) {
	e.preventDefault();
	e.stopPropagation();
	return false;
}

export async function makeServiceWorkerCall(action: string, data: object, timeout?: number) {
	if (!window.navigator.serviceWorker?.controller?.postMessage) {
		throw new Error("Service worker not available.");
	}

	const requestRef = uuid4();

	const message = {
		action: action,
		data: data,
		ref: requestRef,
	};

	const callResponsePromise = new Promise((resolve, reject) => {
		let timeoutRef: number | null = null;

		if (timeout) {
			timeoutRef = window.setTimeout(() => {
				reject(new Error("Service worker call timed out."));
				window.navigator.serviceWorker?.removeEventListener("message", messageListener);
			}, timeout);
		}

		const messageListener = (event: MessageEvent) => {
			if (typeof event.data !== "object" || !event.data) {
				return;
			}

			const { action: responseAction, data, ref: responseRef } = event.data;

			if (requestRef === responseRef && action === responseAction && typeof data === "object") {
				window.navigator.serviceWorker?.removeEventListener("message", messageListener);
				if (timeoutRef) {
					window.clearTimeout(timeoutRef);
				}
				resolve(data);
			}
		};

		window.navigator.serviceWorker?.addEventListener("message", messageListener);
	});

	window.navigator.serviceWorker?.controller?.postMessage(message);

	await callResponsePromise;
}

/**
 * Determine if the app is running within an Android/iOS wrapper via Capacitor
 */
export const isNative = window.Capacitor?.isNativePlatform();

/**
 * Determine the platform running the native app via Capacitor
 */
export function getPlatform(): "web" | "ios" | "android" {
	return window.Capacitor?.getPlatform() as "web" | "ios" | "android";
}

/**
 * Determine if the app is running within an iOS wrapper via Capacitor
 */
export const isiOSApp = getPlatform() === "ios";

/**
 * Determine if the app is running within an Android wrapper via Capacitor
 */
export const isAndroidApp = getPlatform() === "android";

/**
 * Determine if the app is running in the Fellow desktop app
 */
export const isDesktopApp = navigator.userAgent.includes("Fellow");

/**
 * Determine if the app is running on Windows
 */
export const isWindows = parser.is("Windows");

/**
 * Determine if the app is running on MacOS
 */
export const isMacos = parser.is("MacOS");

/**
 * Determine if the app is running on Linux
 */
export const isLinux = parser.is("Linux");

/**
 * Determine if the app is running on an Android web browser
 */
export const isAndroid = parser.is("Android");

/**
 * Determine if the app is running on an iOS web browser
 */
export const isiOS = parser.is("iOS");

/**
 * Determine if the app is running in Chrome
 */
export const isChrome = parser.is("Chrome");

/**
 * Determine if the app is running in Firefox
 */
export const isFirefox = parser.is("Firefox");

/**
 * Determine if the app is running in Safari
 */
export const isSafari = parser.is("Safari");

/* List of app/extension links for this environment */

/* eslint-disable max-len */
export const playStoreLink = `https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=${window.UNIVERSAL_LINKING_BUNDLE_ID}`;
export const appStoreLink = `https://apps.apple.com/ca/app/id${window.IOS_APP_STORE_ID}`;
export const desktopDownloadUri = `/desktop/download/${
	isMacos ? "darwin" : "win32"
}/latest/?utm_source=fellow_core&utm_medium=web`;
export const macOsArmDesktopDownloadUri =
	"/desktop/download/darwin_arm64/latest/?utm_source=fellow_core&utm_medium=web";
export const macOsX64DesktopDownloadUri = "/desktop/download/darwin_x64/latest/?utm_source=fellow_core&utm_medium=web";
export const desktopDownloadLink = `${window.location.origin}${desktopDownloadUri}`;
export const chromeStoreLink = `https://chromewebstore.google.com/detail/fellow-meeting-notes-agen/${window.CHROME_EXTENSION_ID}?utm_source=fellow_core&utm_medium=web`;

const firefoxExtensionProdLink =
	"https://addons.mozilla.org/firefox/addon/fellow/?utm_source=fellow_core&utm_medium=web";
const firefoxExtensionStagingLink = "https://firefoxaddons-staging.fellow.app/fellow-ext-firefox-latest.xpi";
export const mozillaStoreLink =
	window.ENVIRONMENT === "staging" ? firefoxExtensionStagingLink : firefoxExtensionProdLink;

export const msTeamsStoreLink = `https://appsource.microsoft.com/en-us/product/office/${window.MICROSOFT_TEAMS_APPSOURCE_ID}/?utm_source=fellow_core&utm_medium=web`;
export const slackAppLink = `https://slack.com/apps/${window.SLACK_APP_ID}/?utm_source=fellow_core&utm_medium=web`;
export const zapierZapLink = "https://zapier.com/apps/fellow/integrations/?utm_source=fellow_core&utm_medium=web";
export const asanaAppLink = "https://asana.com/apps/fellow/?utm_source=fellow_core&utm_medium=web";

const zoomStagingLink = `
	https://zoom.us/oauth/authorize?response_type=code&client_id=4KPmEWAMTRuhuQPs7hCraw
	&redirect_uri=https://staging.fellow.co/zoom/oauth/redirect
`;
const zoomProdLink = "https://marketplace.zoom.us/apps/PSqbhfgwRCe6MHUjwql6eA/?utm_source=fellow_core&utm_medium=web";
export const zoomStoreLink = window.ENVIRONMENT === "staging" ? zoomStagingLink : zoomProdLink;
/* eslint-enable max-len */

export function withQueryParams(url: string, query: { [key: string]: string }) {
	return queryString.stringifyUrl({ url, query });
}

/**
 * Determine if the app is running in a web browser
 */
export const isWeb = !isNative && !isDesktopApp;

/**
 * Determine if the app is running within Zoom
 */
export const isZoom = navigator.userAgent.includes("ZoomApps/1");

/**
 * Determine if the app is running within the MS Teams desktop app
 */
export const isTeams = window.location.pathname.startsWith("/apps/teams");

/**
 * Determine if the app is running on a Mobile browser
 */
export const isMobile = parser.getPlatformType() === "mobile";

/**
 * Determine if the app is running on a Tablet browser
 */
export const isTablet = parser.getPlatformType() === "tablet";

/**
 * Determine if the app is running on a Desktop browser
 */
export const isDesktop = parser.getPlatformType() === "desktop";

/**
 * Determine if the hardware device is touch capable
 */
export const isTouchScreen = "ontouchstart" in window || navigator.maxTouchPoints > 0;

/**
 * Determine if the app is running in Companion Mode
 */
export const inCompanionMode = navigator.userAgent.includes("FellowCompanionMode");

/**
 * Determine if the app is running in a Zoom Meeting
 */
export const inZoomMeeting = sessionStorage.getItem("lib.zoomContext") === "meeting";

/**
 * Determine if the app is running in Google Meet, MS Teams (via the browser extension) or Zoom Meeting
 */
export const inVideocallMode = inCompanionMode || inVideocall || inZoomMeeting;

/**
 * Determine if the app is running in Google Calendar or MS Outlook
 */
export const inCalendarMode = inCalendar;

/** Symbol for Command key on macOS, or Control key on other platforms */
export const CtrlOrCmd = isMacos ? "⌘" : "Ctrl";
export const UpOrOption = isSafari ? "⌥" : "⇧";

/**
 * Return true if the Command key on macOS, or the Control key on other platforms,
 * was being held when `event` was fired.
 */
export const isCtrlOrCmd = (event: KeyboardEvent | MouseEvent): boolean => {
	if (isMacos) {
		return event.metaKey;
	} else {
		return event.ctrlKey;
	}
};

/**
 * Truncate a string to fit within a certain length, adding ellipsis at the end
 * if the text was truncated.
 *
 * @param text The text to truncate
 * @param length The maximum length of the output
 */
export function truncate(text: string, length: number): string {
	if (text.length <= length) {
		return text;
	}

	// add an ellipsis character
	return `${text.substring(0, length - 1)}\u{2026}`;
}

export const addBreadcrumb = (args: { category: string; message: string; level: Sentry.SeverityLevel }) => {
	if (window.SENTRY_DSN_PUBLIC) {
		Sentry.addBreadcrumb({
			category: args.category,
			message: args.message,
			level: args.level,
		});
	}
};

export const simpleHash = (s: string) => s.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);

export const displayNoteType = (noteType: string, shared?: boolean) => {
	if (shared) {
		return "Shared";
	}
	switch (noteType) {
		case "meeting":
			return "Meetings";
		case "goalset":
			return window.APP_CONFIG ? window.APP_CONFIG.prioritiesLabel : "Priorities";
		case "oneonone":
			return "1-on-1s";
		case "personal":
			return "Private";
		default:
			return noteType || "";
	}
};

/**
 * Checks if a string is a valid URL by calling the URL constructor on it.
 * @param url potential url
 */
export const isValidUrl = (url: string) => {
	try {
		const u = new URL(url);
		return u.protocol === "http:" || u.protocol === "https:";
	} catch (error) {
		return false;
	}
};

/**
 * It is necessary to define two instances of essentially the same link-detecting regex
 * as the one used for inputRules must finish with '$', but this will not work in feedback
 * since it is not prose (no inputRules) and should instead expect whitespace, new line, or end of line
 */
const linkRegexInner = /(https?:\/{2}[\w.-]+(?::\d{2,5})?(?:[/?#]\S*)?)/;
export const linkRegexNewline = new RegExp(linkRegexInner.source + /\s$/.source, "gm");
export const linkRegexInline = new RegExp(linkRegexInner.source + /(\s|\r|$|\b)/.source, "g");

/**
 * Takes a potential URL and if it can't be parsed by the URL constructor ads https to the start, if that helps
 * @param url potential url
 */
export const addHttpsToNonUrls = (url: string) => {
	if (!isValidUrl(url)) {
		const newURL = `https://${url}`;
		if (isValidUrl(newURL)) {
			return newURL;
		}
	}

	return url;
};

/**
 * Generates a ui-avatar URL with better background colors
 */
export interface UIAvatarOptions {
	digits?: boolean;
	size?: number;
	background?: string;
	color?: string;
	rounded?: 0 | 1;
	uppercase?: 0 | 1;
	bold?: 0 | 1;
	format?: "png" | "svg";
	// This is intended to be used for icon avatars
	textForColor?: string;
}

export const uiAvatar = (
	value: string | number | IconDefinition,
	{
		size = 200,
		background = undefined,
		color = "FFFFFF",
		rounded = 0,
		uppercase = 1,
		bold = 1,
		format = "svg",
		textForColor = "",
	}: UIAvatarOptions = {},
): string => {
	let contents, fontSize, bg;
	if (typeof value === "number") {
		contents = value > 99 ? "99+" : `+${value}`;
		bg = background ?? str2ColorHex(contents);
		fontSize = 0.35;
	} else if (typeof value === "string") {
		contents = [...value][0] ?? "?"; // We have to spread the string due to emoji / unicode characters
		contents = uppercase ? contents.toUpperCase() : contents.toLowerCase();
		bg = background ?? str2ColorHex(contents);
		fontSize = 0.6;
	} else {
		// This enables us to use icons as values for Avatars
		// It is not very pretty at the moment and could use some love
		// We might be able to send the <FontAwesome /> JSX element and manipulate that here
		contents = `
		<svg
			aria-hidden="true"
			focusable="false"
			data-prefix="far"
			data-icon="${value.iconName}"
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="-175 -125 1000 800">
			${
				Array.isArray(value.icon[4])
					? value.icon[4].reduce(
							(x, y, i) => `${i == 1 ? `<path fill="#${color}" d=${x} />` : x} <path fill="#${color}" d=${y} />`,
						)
					: `<path fill="#${color}" d="${value.icon[4]}" />`
			}
			<path fill="#${color}" d="${value.icon[4]}" />
		</svg>`;
		bg = background ?? str2ColorHex(textForColor);
		fontSize = 0.6;
	}
	if (format !== "svg") {
		return (
			`https://ui-avatars.com/api/${encodeURIComponent(contents)}` +
			`/${size}/${bg}/${color}/${contents.length}/${fontSize}/${rounded}/${uppercase}/${bold}/${format}/`
		);
	} else {
		// eslint-disable-next-line max-len
		const fontFamily = `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;`;

		const svg = `
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			width="${size}px"
			height="${size}px"
			viewBox="0 0 ${size} ${size}"
			version="1.1"
		>
			<${rounded ? "circle" : "rect"}
				fill="#${bg}"
				cx="${size / 2}"
				width="${size}"
				height="${size}"
				cy="${size / 2}"
				r="${size / 2}"
			/>
			${
				typeof value === "string" || typeof value === "number"
					? `<text
				x="50%"
				y="50%"
				style="color: #${color};line-height: 1;font-family: ${fontFamily}"
				alignment-baseline="middle"
				text-anchor="middle"
				font-size="${size * fontSize}"
				font-weight="${bold ? 600 : 400}"
				dy=".1em"
				dominant-baseline="middle"
				fill="#${color}"
			>
				${contents}
			</text>`
					: contents
			}
		</svg>
		`;
		return `data:image/svg+xml,${encodeURIComponent(svg)}`;
	}
};

/** List of emails to ignore when suggesting emails to invite  */
export const emailGreylist = new RegExp(
	`^.*(all|team|hello|info|admin|services|office|hq|exec|devs|
	development|design|sales|marketing|support|success|billing|accounting|fellowship|fellows).*@.*$`,
);

export const emailRegex = new RegExp(
	'^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@' +
		"((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$",
);

export const isValidEmail = (value: string): boolean => {
	return emailRegex.test(value);
};

export const isValidName = (value: string): boolean => {
	return /^[a-zA-Z1-9 ]+$/.test(value);
};

/**
 * Returns a function that checks whether an object has a non-nullish value at a given key
 *
 * @remarks
 *
 * The standard use case is for filtering an array of objects:
 *
 * ```ts
 * let myArray = [
 * 	{ foo: "bar" },
 * 	{ foo: undefined },
 * 	{ foo: null },
 * ];
 *
 * myArray.filter(hasKey("foo")); // [{ foo: "bar" }]
 * ```
 *
 * @privateRemarks
 *
 * The grotesque types are so that TypeScript knows it's a type guard, meaning that after we use it in `Array.filter`,
 * we won't have to worry about null checks on that key in the returned array.
 */
// prettier-ignore
export const hasKey = <T, K extends keyof T>(key: K) => (obj: T): obj is T & { [KK in K]: NonNullable<T[KK]> } =>
	obj[key] != null;

export const blur = () => {
	if (document.activeElement && document.activeElement instanceof HTMLElement) {
		document.activeElement.blur();
	}
};

/**
 * This function takes an edge array and turns it into an array of Nodes with the correct type
 * It's useful for the results of graphQL queries that can't or are not easily made non nullable.
 * @example
 * // returns array of calendar events
 * reduceEdgesToNodes<NonNullable<inMeetingEvents["events"]["edges"][0]>>(response.viewer.events.edges)
 * @param edges
 */
export function reduceEdgesToNodes<T extends Readonly<{ node: object | null }>>(
	edges: ReadonlyArray<T | null> | null | undefined,
) {
	type edgeType = NonNullable<T>;
	type edgeWithNodeType = edgeType & { node: NonNullable<edgeType["node"]> };

	function isEdgeWithNode(edge: T | null): edge is edgeWithNodeType {
		return !!edge && hasOwnProperty(edge, "node") && edge.node !== null;
	}

	return edges ? edges.filter(isEdgeWithNode).map((edge: edgeWithNodeType) => edge.node) : [];
}

export type NodesFromEdges<
	T extends Readonly<{ edges: ReadonlyArray<{ node: unknown } | null> | null | undefined }> | null,
> = NonNullable<NonNullable<NonNullable<T>["edges"]>[number]> extends { node: infer U } ? NonNullable<U> : never;

export function fromEntries<K extends string | number | symbol, V>(entries: [K, V][]): Record<K, V> {
	const obj = {} as Record<K, V>;
	entries.forEach(([k, v]) => (obj[k] = v));
	return obj;
}

export function hexToRGB(input: string) {
	return chunk(input.startsWith("#") ? input.substring(1) : input, 2)
		.map(seg => parseInt(seg.join(""), 16).toString())
		.join(",");
}

/**
 * Converts a hex color to HSL
 * @param color A color in hex format
 * @returns color.hue: number between 0 and 360
 * @returns color.saturation: number between 0 and 100
 * @returns color.lightness: number between 0 and 100
 */
export function hexToHSL(color: string) {
	// Math based on this article: https://www.niwa.nu/2013/05/math-behind-colorspace-conversions-rgb-hsl/
	const rgb = hexToRGB(color);
	const [r, g, b] = rgb.split(",").map(v => parseInt(v, 10) / 255);
	const max = Math.max(r, g, b);
	const min = Math.min(r, g, b);
	let lightness = (max + min) / 2;
	let hue = 0;
	let saturation = 0;

	if (max !== min) {
		if (lightness <= 0.5) saturation = (max - min) / (max + min);
		else saturation = (max - min) / (2 - max - min);

		if (max === r) hue = (g - b) / (max - min);
		else if (max === g) hue = 2 + (b - r) / (max - min);
		else hue = 4 + (r - g) / (max - min);

		hue *= 60;
		if (hue < 0) hue += 360;
	}

	saturation *= 100;
	lightness *= 100;

	return { hue, saturation, lightness };
}

export function mixWithWhite(input: string, selfWeight: number) {
	const w1 = selfWeight;
	const w2 = 1 - w1;
	return `#${chunk(input.startsWith("#") ? input.substring(1) : input, 2)
		.map(seg => Math.round(parseInt(seg.join(""), 16) * w1 + 255 * w2).toString(16))
		.join("")}`;
}

export function opacity(input: string, opacity: number) {
	return input + Math.round(0xff * opacity).toString(16);
}

export const libStore = store.namespace("lib");

export function getAnonymousUserIcon(userName?: string): IconDefinition {
	if (!userName || !ANONYMOUS_ICONS[userName]) {
		return Duck;
	}
	return ANONYMOUS_ICONS[userName];
}

/**
 * Pluralize a word depending on the value of `n`
 *
 * @param n The number in question
 * @param singular The singular form of the word
 * @param plural The plural form of the word – defaults to `singular` with an S on the end
 */
export function nGetText(n: number, singular: string, plural = `${singular}s`): string {
	return n === 1 ? singular : plural;
}

export function plural(number: number) {
	if (number > 1) {
		return "s";
	}
	return "";
}

/**
 * Converts a ms amount to a difference in humanized time.
 * eg "less than one day", "3 days", "4 years"
 * @param expiresIn
 */
export function millisecondsToHumanizedDate(
	expiresIn: number,
	accuracy: "years" | "months" | "days" | "hours" | "minutes" = "days",
) {
	let remainingSeconds = expiresIn / 1000;

	const SECONDS_IN_A_MINUTE = 60;
	const SECONDS_IN_A_HOUR = SECONDS_IN_A_MINUTE * 60;
	const SECONDS_IN_A_DAY = SECONDS_IN_A_HOUR * 24;
	const SECONDS_IN_A_MONTH = SECONDS_IN_A_DAY * 30;
	const SECONDS_IN_A_YEAR = SECONDS_IN_A_DAY * 365;

	const years = Math.floor(remainingSeconds / SECONDS_IN_A_YEAR);
	remainingSeconds -= SECONDS_IN_A_YEAR * years;
	const months = Math.floor(remainingSeconds / SECONDS_IN_A_MONTH);
	remainingSeconds -= SECONDS_IN_A_MONTH * years;
	const days = Math.floor(remainingSeconds / SECONDS_IN_A_DAY);
	remainingSeconds -= SECONDS_IN_A_DAY * days;
	const hours = Math.floor(remainingSeconds / SECONDS_IN_A_HOUR);
	remainingSeconds -= SECONDS_IN_A_HOUR * hours;
	const minutes = Math.floor(remainingSeconds / SECONDS_IN_A_MINUTE);

	if (years) return `${years} year${plural(years)}`;
	if (accuracy === "years") return "less than one year";
	if (months) return `${months} month${plural(months)}`;
	if (accuracy === "months") return "less than one month";
	if (days) return `${days} day${plural(days)}`;
	if (accuracy === "days") return "less than one day";
	if (hours) return `${hours} hour${plural(hours)}`;
	if (accuracy === "hours") return "less than one hour";
	if (minutes) return `${minutes} minute${plural(minutes)}`;
	return "less than one minute";
}

export function humanizeDateAgo(date: string) {
	const now = moment();
	const givenDate = moment(date);
	const minutes = now.diff(givenDate, "minutes");

	if (minutes === 0) {
		return "Just now";
	} else if (now.diff(givenDate, "hours") < 1) {
		return `${minutes}m ago`;
	} else if (now.diff(givenDate, "days") < 1) {
		const hours = now.diff(givenDate, "hours");
		return `${hours}h ago`;
	} else if (givenDate.isSame(moment().subtract(1, "days"), "day")) {
		return "Yesterday";
	} else {
		return givenDate.format("MMM D, YYYY");
	}
}

export function meetingDateFormat(start: string | Date, end: string | Date, isAllDay: boolean) {
	if (moment(end).isAfter(moment(start).endOf("day"), "seconds")) {
		if (moment(start).hour() === 0 && moment(end).hour() === 0) {
			const startDate = moment(start).format("MMM DD");
			const endDate = isAllDay ? moment(end).subtract(1, "day").format("MMM DD") : moment(end).format("MMM DD");
			if (startDate === endDate) {
				return startDate;
			} else {
				return `${startDate} - ${endDate}`;
			}
		} else {
			const startDate = moment(start).format("MMM DD [at] h:mm A");
			const endDate = moment(end).format("MMM DD [at] h:mm A");
			return `${startDate} - ${endDate}`;
		}
	} else {
		let eventDate;
		const eventStartMoment = moment(start);
		const now = moment();

		if (eventStartMoment.isSame(now, "day")) {
			eventDate = "Today";
		} else if (eventStartMoment.isSame(moment().subtract(1, "days"), "day")) {
			eventDate = "Yesterday";
		} else if (eventStartMoment.isSame(moment().add(1, "days"), "day")) {
			eventDate = "Tomorrow";
		} else if (eventStartMoment.diff(now, "months") >= 6) {
			eventDate = eventStartMoment.format("MMM D, YYYY");
		} else {
			eventDate = moment(start).format("MMM DD");
		}

		const startTime = moment(start).format("h:mm A");
		const endTime = moment(end).format("h:mm A");
		return `${eventDate} at ${startTime} - ${endTime}`;
	}
}

export function getDateString(date: Date) {
	if (isToday(date)) {
		return "Today";
	} else if (isTomorrow(date)) {
		return "Tomorrow";
	} else if (isYesterday(date)) {
		return "Yesterday";
	} else {
		return date.toLocaleDateString("en", {
			month: "short",
			day: "numeric",
			year: isThisYear(date) ? undefined : "numeric",
		});
	}
}

export function getDurationString(durationInSeconds: number) {
	const minutes = durationInSeconds >= 60 ? Math.round(durationInSeconds / 60) : 0;
	const hours = Math.floor(minutes / 60);
	let parts = [];

	if (hours > 0) {
		parts.push(`${hours}h`);
	}

	if (minutes % 60 > 0) {
		const units = hours > 0 ? "m" : " min";
		parts.push(`${minutes % 60}${units}`);
	}

	if (minutes === 0 && hours === 0) {
		parts.push(`${Math.floor(durationInSeconds) % 60} sec`);
	}

	return parts.join(" ");
}

export function download(filename: string, blob: Blob) {
	const a = window.document.createElement("a");
	a.href = window.URL.createObjectURL(blob);
	a.download = filename;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
}

export function contactSupport(message = "") {
	if (window.Intercom) {
		return window.Intercom("showNewMessage", message);
	}

	const mailUrl = `mailto:help@fellow.app?body=${message ? encodeURIComponent(message) : ""}`;
	window.open(mailUrl, "_blank", "noopener noreferrer");
}

/* Replace this with a list of article IDs pulled from Intercom to validate */
export type ArticleID = string;

export function showHelpCenter(articleId?: string) {
	// The Intercom launcher has a bug with `showArticle` where it gets in a bad state
	// Keep this commented out until it's fixed

	// if (window.Intercom) {
	// 	if (articleId) {
	// 		window.Intercom("showArticle", articleId);

	// 		// Shrink the size of the Intercom help window
	// 		const intervalId = window.setInterval(() => {
	// 			const largeFrame = document.querySelector<HTMLDivElement>("div.intercom-messenger-frame.intercom-4n9m1z");
	// 			if (!largeFrame) return;

	// 			const frame = document.querySelector<HTMLIFrameElement>("[data-intercom-frame]");
	// 			if (!frame) return;

	// 			const toggle = frame.contentDocument?.querySelector<HTMLButtonElement>("[data-testid=toggle-expansion]");
	// 			toggle?.click();
	// 			clearInterval(intervalId);
	// 		}, 200);

	// 		setTimeout(() => {
	// 			clearInterval(intervalId);
	// 		}, 10000);

	// 		return;
	// 	}

	// 	// Hidden API method that opens Intercom on a specific tab
	// 	return window.Intercom("showSpace", "help");
	// }

	if (articleId) {
		return window.open(
			`https://help.fellow.app/en/articles/${articleId}`,
			isDesktopApp || isTeams ? "_self" : "_blank",
			"noopener",
		);
	}

	return window.open("https://help.fellow.app", isDesktopApp || isTeams ? "_self" : "_blank", "noopener");
}

/**
 * Copy some text to the user's clipboard.
 *
 * When copying something async, call this function synchronously and pass it
 * the promise instead of awaiting it outside of this function.
 */
export async function copyToClipboard(text: string | Promise<string>): Promise<void> {
	// In MS Teams (and browser extensions) the fancier clipboard APIs we use below don't work due to some
	// permission error (maybe due to how Fellow is running within Teams).
	// Instead, we'll just fall back to the old-school way, where the text is
	// written to a DOM node, selected, and then document.execCommand("copy") is
	// used to copy to the clipboard. We can't use this all the time due to the
	// issues in the comment below.
	if (isTeams || inCalendar || inVideocall) {
		if (!copyToClipboardWithExecCommand(await text)) {
			throw new Error("Failed to copy to clipboard");
		}
		return;
	}

	if (!(text instanceof Promise)) {
		return await navigator.clipboard.writeText(text);
	}

	// To write to the clipboard requires a "transient user activation" [1]. This
	// means that you can only do so after some user interactions and before some
	// amount of time has passed after that interaction. This makes it difficult
	// to copy the result of an API request to the clipboard.
	//
	// In Chrome and Safari (and Firefox if a feature flag is enabled [2]), we
	// can use the async clipboard API, where we pass a Promise<string> to a
	// ClipboardItem. Otherwise, we just await the text and hope that the timeout
	// has yet to expire when we write it to the clipboard.
	//
	// [1]: https://developer.mozilla.org/en-US/docs/Glossary/Transient_activation
	// [2]: https://developer.mozilla.org/en-US/docs/Web/API/ClipboardItem/ClipboardItem
	if (typeof ClipboardItem !== "undefined") {
		// MDN says that we can pass a promise of either a string or a blob as the
		// data in the ClipboardItem, but Chrome expects a blob.
		const blobPromise = text.then(text => new Blob([text], { type: "text/plain" }));
		return await navigator.clipboard.write([new ClipboardItem({ "text/plain": blobPromise })]);
	} else {
		return await navigator.clipboard.writeText(await text);
	}
}

/**
 * Type guards to tell us whether a given object has a User or Stream fragment
 * on it. This shouldn't strictly be necessary, but for whatever reason Relay
 * only types the value of `__typename` more specifically than `string` on GraphQL
 * _union_ types. This allows us to easily render arrays of both.
 */
export function isUser(item: StreamData | UserData): item is UserData {
	return item.__typename === "User";
}

export const LAST_LOCATION_KEY = "lastAppLocation";

const ROMAN_DIGITS = [
	"",
	"C",
	"CC",
	"CCC",
	"CD",
	"D",
	"DC",
	"DCC",
	"DCCC",
	"CM",
	"",
	"X",
	"XX",
	"XXX",
	"XL",
	"L",
	"LX",
	"LXX",
	"LXXX",
	"XC",
	"",
	"I",
	"II",
	"III",
	"IV",
	"V",
	"VI",
	"VII",
	"VIII",
	"IX",
].map(i => i.toLowerCase());

// https://stackoverflow.com/a/9083076/4427600
export function toRomanOrder(num: number) {
	const digits = String(+num).split("");
	let roman = "";
	let i = 3;
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	while (i--) roman = (ROMAN_DIGITS[+digits.pop()! + i * 10] || "") + roman;
	return Array(+digits.join("") + 1).join("m") + roman;
}

export function toAlphabeticalOrder(num: number) {
	const START = "a".charCodeAt(0);
	// https://stackoverflow.com/a/62198235/4427600
	function numberToBase26(val: number, tail = ""): string {
		if (val <= 26) {
			return `${String.fromCharCode(val + START - 1)}${tail}`;
		}

		const remainder = val % 26 || 26;
		const division = Math.trunc(val / 26) - (remainder === 26 ? 1 : 0);

		return numberToBase26(division, `${String.fromCharCode(remainder + START - 1)}${tail}`);
	}
	return numberToBase26(num);
}

export function capitalizeFirstLetter(string: string) {
	return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}

export function decapitalizeFirstLetter(string: string) {
	return `${string.charAt(0).toLowerCase()}${string.slice(1)}`;
}

export function useNewest<T>(valueA: T, valueB: T): T {
	const [newest, setNewest] = useState(valueA);

	useEffect(() => {
		setNewest(valueB);
	}, [valueB]);

	useEffect(() => {
		setNewest(valueA);
	}, [valueA]);

	return newest;
}

function isArray(value: unknown): value is unknown[] {
	return Array.isArray(value);
}

export function getFirstUserFacingError(errors: unknown): string | null {
	let iterErrors;

	// this first if statement captures errors that come from catching exceptions using the commitMutationPromise
	// onError callback since they are rejected before onComplete is run, meaning the error isn't split up the same way
	// that they are in onComplete. The errors in this object are under the e.source.errors value in the error
	// parameter e that the onError callback uses. This checks if the input value `errors` in this function is this
	// object and the error array that we can find the userFacingMessage from ex: /lib/desktopTrayApi/meetingRecorder.ts
	if (
		typeof errors === "object" &&
		errors !== null &&
		hasOwnProperty(errors, "source") &&
		typeof errors.source === "object" &&
		errors.source !== null &&
		hasOwnProperty(errors.source, "errors") &&
		isArray(errors.source.errors)
	) {
		iterErrors = errors.source.errors;
	} else if (!isArray(errors)) {
		iterErrors = [errors];
	} else {
		iterErrors = errors;
	}

	for (const error of iterErrors) {
		if (typeof error !== "object" || error === null) continue;
		if (hasOwnProperty(error, "userFacingMessage") && typeof error.userFacingMessage === "string") {
			return error.userFacingMessage;
		}
	}

	return null;
}

export function isNotNull<G>(value: G): value is NonNullable<G> {
	return value !== null;
}
