/**
 * Provides a React context that allows opening modals that are not rendered within the parent tree. This is useful
 * when e.g. opening a modal from a dropdown selection, where the containing element would become unmounted.
 *
 * Typical usage involves rendering a `ModalRoot` near the root of the application, accessing the context with a
 * call to the `useModals()` hook, and opening a modal by passing a render callback to `modals.open`.
 */
import React from "react";
import { useLocation } from "react-router";

import "@reach/dialog/styles.css";
import fscreen from "fscreen";

export interface ModalProps<T> {
	/**
	 * @param result an optional result that will be used for resolving the promise returned when opening this modal.
	 */
	close(result?: T | null): void;
}

interface ModalOptions {
	exclusive?: boolean;
	exclusiveId?: string;
	onConflict?: "ignore" | "replace";
	persistOnNavigation?: boolean;
}

interface ModalContext {
	/**
	 * Open a modal within the current modal context.
	 *
	 * @param render a function that returns a Reach Dialog component. It will be passed a `close` prop which can be used
	 * to close this modal. Note that this is *NOT* a render method! It will only be called once when opening the modal,
	 * and its props will not be updated. Any interactivity must be handled within the component itself.
	 * @param options.exclusive if true, this will prevent other modals marked exclusive from being opened if
	 * onConflict is ignored or replace the existing open modal if onConflict is replace.
	 * @param options.exclusiveId Makes it so that if other modals are open with the same id we ignore it
	 * if onConflict is ignore or replace the existing open modal if onConflict is replace.
	 * @returns a promise that resolves when the modal is closed.
	 */
	open<T>(render: (props: ModalProps<T>) => JSX.Element, options?: ModalOptions): Promise<T | null>;
}

export const ModalContext = React.createContext<ModalContext>({
	open() {
		return new Promise(resolve => resolve(null));
	},
});
ModalContext.displayName = "ModalContext";

export const ModalConsumer = ModalContext.Consumer;

export function useModals(): ModalContext {
	return React.useContext(ModalContext);
}

/**
 * Prompts provide an API that allows opening modals without specifying a context. For this to work, they must have a
 * way to access the "main" modal stack. Under the assumption that there is only ever one ModalRoot being rendered, we
 * keep track of it via a global to allow prompts to access it. This should go away once the prompts API is reworked.
 */
// eslint-disable-next-line no-underscore-dangle
export let __globalModalContext: ModalContext | null = null;

// eslint-disable-next-line no-underscore-dangle
export let __isClosingModal = false;

// Separate component so ModalRoot does not depend on location
function ClearModalsOnNavigation({ setModals }: { setModals: React.Dispatch<React.SetStateAction<JSX.Element[]>> }) {
	const { pathname, search } = useLocation();

	// Clear the modal stack whenever the location changes.
	React.useEffect(() => {
		setModals(oldModals => {
			if (oldModals.length > 0) {
				return oldModals.filter(modal => modal.props.options?.persistOnNavigation);
			}
			return oldModals; // skip re-render
		});
	}, [pathname, search, setModals]);

	return null;
}

/**
 * ModalRoot sets up a context provider that provides a mechanism to open modals. It will manage
 * stacking the modals correctly.
 */
export function ModalRoot(props: React.PropsWithChildren<{}>): JSX.Element {
	const [modals, setModals] = React.useState<JSX.Element[]>([]);

	const context: React.RefObject<ModalContext> = React.useRef({
		open<T>(render: (props: ModalProps<T>) => JSX.Element, options?: ModalOptions): Promise<T | null> {
			return new Promise(resolve => {
				setModals(modals => {
					let existingModals = [...modals];
					let deletedModalKey = -1;
					// If exclusive is set we want to check if an existing modal, in the case of exclusiveId existing
					// with the same id, already exists. If so we check the onConflict flag to see if we return
					// The existing modal or if we replace it with a new one
					if (options?.exclusive || options?.exclusiveId) {
						const exclusiveModalIndex = options?.exclusive
							? existingModals.findIndex(el => el.props.options?.exclusive)
							: existingModals.findIndex(el => el.props.options?.exclusiveId === options.exclusiveId);
						if (exclusiveModalIndex >= 0) {
							if (options.onConflict === "ignore") {
								return existingModals;
							}
							const deletedModals = existingModals.splice(exclusiveModalIndex, 1);
							deletedModalKey = parseInt(deletedModals[0].key?.toString() ?? "0");
						}
					}

					let modal = render({
						close: (result: T | null = null) => {
							__isClosingModal = true;
							setModals(mdls => mdls.filter(el => el !== modal));

							// enables tooltips to not be displayed on input re-focus after a modal is closed
							setTimeout(() => {
								__isClosingModal = false;
							}, 0);

							resolve(result);
						},
					});

					// Add a key since this will be rendered as part of a list of modals.
					// We might change the existing modals array as part of this operation
					// So we can't rely only on existingModals.length
					const latestModalKey =
						existingModals && existingModals.length > 0
							? parseInt(existingModals[existingModals.length - 1].key?.toString() ?? "0") + 1
							: 1;
					modal = React.cloneElement(modal, {
						// If we are replacing the only existing modal the latestModalKey and the deletedModalKey will both be 1
						key: latestModalKey !== deletedModalKey ? latestModalKey : latestModalKey + 1,
						options,
					});

					// In fullscreen mode, the modal gets rendered outside of the fullscreen'd
					// element and does not show. For now, we exit fullscreen mode when opening a
					// modal. There are some better solutions here: either have a new modal root as part
					// of the fullscreen root element, have the Fullscreen component be modal-aware,
					// or portal into the fullscreen component from this modal root.
					if (fscreen.fullscreenElement) fscreen.exitFullscreen();

					return [...existingModals, modal];
				});
			});
		},
	});

	__globalModalContext = context.current;

	return (
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		<ModalContext.Provider value={context.current!}>
			<ClearModalsOnNavigation setModals={setModals} />
			{props.children}
			{modals}
		</ModalContext.Provider>
	);
}
